import * as React from "react";
import { IntlProvider } from "react-intl";

const wrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale;
  const message = require(`../compiled-lang/${locale ? locale : "de"}.json`);

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={message}
      defaultLocale="de"
    >
      {element}
    </IntlProvider>
  );
};

export { wrapPageElement };

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-shop-cart-tsx": () => import("./../../../src/pages/shop/cart.tsx" /* webpackChunkName: "component---src-pages-shop-cart-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-pages-shop-shopify-product-handle-tsx": () => import("./../../../src/pages/shop/{ShopifyProduct.handle}.tsx" /* webpackChunkName: "component---src-pages-shop-shopify-product-handle-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "slice---src-components-navigation-tsx": () => import("./../../../src/components/Navigation.tsx" /* webpackChunkName: "slice---src-components-navigation-tsx" */)
}


import React from "react";

import { StoreProvider } from "./src/context/storeContext";
import "./src/styles/global.css";

export { wrapPageElement } from "./src/wrap-page-element";

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

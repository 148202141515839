import React, { createContext, useEffect, useState } from "react";
import fetch from "isomorphic-fetch";
import Client, { Checkout, CheckoutLineItem } from "shopify-buy";

const client = Client.buildClient(
  {
    apiVersion: process.env.GATSBY_STOREFRONT_VERSION,
    domain:
      process.env.GATSBY_SHOPIFY_STORE_CUSTOM_URL ||
      process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  // @ts-ignore
  fetch
);

const defaultValues = {
  client,
  loading: false,
  addVariantToCart: (
    _variantId: string,
    _quantity: string,
    _customAttributes: any
  ) => {},
  removeLineItem: (_checkoutId: string, _lineItemId: string) => {},
  updateLineItem: (
    _checkoutId: string,
    _lineItem: CheckoutLineItem,
    _quantity: string
  ) => {},
  checkout: null,
  didJustAddToCart: false,
};

export const StoreContext = createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState<Checkout>(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);

  const setCheckoutItem = (checkout: Checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutId && existingCheckoutId !== `null`) {
        try {
          const existingCheckout =
            await client.checkout.fetch(existingCheckoutId);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (
    variantId: string,
    quantity: string,
    customAttributes: any
  ): Promise<void> => {
    setLoading(true);

    customAttributes = customAttributes
      ? Object.entries(customAttributes)?.map((item) => ({
          key: item[0],
          value: item[1],
        }))
      : {};

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        ...(customAttributes ? { customAttributes } : {}),
      },
    ];

    return client.checkout
      .addLineItems(checkout.id, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 1000);
      });
  };

  const removeLineItem = (
    checkoutId: string,
    lineItemId: string
  ): Promise<void> => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (
    checkoutId: string,
    lineItem: CheckoutLineItem,
    quantity: string
  ): Promise<void> => {
    setLoading(true);

    const lineItemsToUpdate = [
      {
        id: lineItem.id,
        quantity: parseInt(quantity, 10),
        variantId: lineItem.variant.id,
      },
    ];

    return client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
